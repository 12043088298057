import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock} from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions for Advanced MSI-H/dMMR Endometrial Carcinoma`,
    keywords: `keytruda adverse reactions advanced msi-h/dmmr endometrial carcinoma`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions in advanced MSI-H/dMMR endometrial carcinoma associated with the KEYNOTE-158 clinical trial.`,
    schemaJsonLD: [
        '{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-endometrial-carcinoma/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE⁠-⁠158 (Cohorts D and K)","description":"The most common adverse reactions for KEYTRUDA as a single agent (reported in ≥20% of patients) were fatigue, musculoskeletal pain, rash, diarrhea, pyrexia, cough, decreased appetite, pruritus, dyspnea, constipation, pain, abdominal pain, nausea, and hypothyroidism."}',
    '{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-endometrial-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}'
    ]
}

//from /src/util/data/indications.js
const indicationId = 30;
const jobCode = jobCodes[32].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;158 (Cohorts D and K)'},
]

const Page = ({ location }) => {

    return (
        // needs job code
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }> 
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title="Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;158 (Cohorts D and K)" definitions="NSCLC = non-small cell lung cancer.">
                            <TextBlock definitions="NSCLC = non–small cell lung cancer.">
                            <p>Among the 90 patients with MSI&#8288;-&#8288;H or dMMR endometrial carcinoma enrolled
                            in KEYNOTE&#8288;-&#8288;158 treated with KEYTRUDA as a single agent, the median
                            duration of exposure to KEYTRUDA was 8.3 months (range: 1 day–26.9
                            months).</p>
                            <p>Adverse reactions occurring in patients with endometrial carcinoma
                            were similar to those occurring in 2,799 patients with melanoma or
                            NSCLC treated with KEYTRUDA as a single agent.</p>
                            <p>The most common adverse reactions for KEYTRUDA as a single
                            agent (reported in ≥20% of patients) were fatigue, musculoskeletal pain, 
                            rash, diarrhea, pyrexia, cough, decreased appetite, pruritus, dyspnea, 
                            constipation, pain, abdominal pain, nausea, and hypothyroidism.</p>
                            </TextBlock>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
